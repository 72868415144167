/*---- Start Common Css ----*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
    scroll-behavior: smooth;
}
body {
    background : #F2F2F2;
    font-family: 'Montserrat', sans-serif;
}
#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.navbarContain {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    padding: 10px 0;
}
#main {
    margin: 40px 0;
    min-height: calc(100vh - 66px - 311px);
    display: flex;
    flex-direction: column;
    place-content: center;
}
ul
{
    padding: 0;
    margin: 0;
}
ul li
{
    list-style: none;
}
a
{
    text-decoration: none;
}
a:-webkit-any-link:focus-visible
{
    outline: 0;
}
textarea
{
    resize: none;
}
textarea:focus-visible {
    outline: 0;
}
.barIcon
{
    font-size: 26px;
    color: #fff;
}
.containerBox
{
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 24px;
    position: relative;
    height: 100%;
}
.headTxt
{
    font-size: 30px;
    color: #000000;
    margin: 0;
}
.headTxtBlue {
    font-size: 27px;
    color: #435FB5;
    margin: 0;
    font-weight: 500;
}
.headTxtBlueSmall {
    font-size: 22px;
    color: #435FB5;
    margin: 0;
    font-weight: 600;
    letter-spacing: 1px;
}
.heading_
{
    font-size: 20px;
    color: #262626;
    margin: 0;
    font-weight: 700;
}
.headingSmall_
{
    font-size: 16px;
    color: #343434;
    margin: 0;
    font-weight: 500;

}
.headingTitle_
{
    font-size: 22px;
    color: #343434;
    margin: 0;
    font-weight: 600;
}
.headingTitleSmall_
{
    font-size: 12px;
    color: #343434;
    margin: 0;
    font-weight: bold;
}
.headingTitleLight_
{
    font-size: 12px;
    color: #343434;
    margin: 0;
    font-weight: 500;
}
.heading_Blue
{
    font-size: 40px;
    color: #1C3276;
    margin: 0;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 57px;
}
.textInner
{
    font-size: 12px;
    color: #575757;
    margin: 0;
}
.textInnerOuter
{
    font-size: 12px;
    color: #9A9A9A;
    margin: 0;
}
.subtextInner
{
    font-size: 15px;
    color: #8C8C8C;
    margin: 0;
}
.innerTxt
{
    font-size: 16px;
    color: #262626;
    margin: 0;
    font-weight: 500;
}
.innerSubtext {
    color: #343434;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    /* padding-left: 15px; */
}
.innerSubtextSmall {
    font-size: 12px;
    color: #4C4C4C;
    margin: 0;
    font-weight: 500;
    text-align: justify;
}
.innerSubtextBlue
{
    color: #1C3276;
    font-size: 18px;
    font-weight: 500;
}
.highLightTxt {
    color: #3B57A9;
    font-weight: bold;
}
.heading20{
    font-size: 20px;
    color: #2c2e31;
    font-weight: 500;
}
.subText13{
    font-size: 13px;
    color: #3B57A9;
    font-weight: 400;
}
.heading48{
    font-size: 48px;
    color: #01346a;
    font-weight: bold;
}
.primaryBtn {
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 8px;
    color: #fff !important;
    font-size: 22px;
    border: none;
    padding: 12px 32px !important;
    display: block;
    /* width: 100%; */
    font-weight: 500 !important;
}
.submitBtn{
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 8px;
    color: #fff !important;
    font-size: 18px;
    border: none;
    padding: 8px 25px !important;
    display: block;
    /* width: 100%; */
    font-weight: 600 !important;
}
.primaryBtnOther
{
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 8px;
    color: #fff !important;
    font-size: 22px;
    border: none;
    padding: 15px 20px !important;
    width: 223px;
    font-weight: 500 !important;
}
.primaryBtnSmall
{
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 8px;
    color: #fff !important;
    font-size: 12px;
    border: none;
    padding: 10px 20px !important;
    font-weight: 500;
}
.secondaryBtn {
    background: #1C3276;
    color: #Fff;
    border-radius: 8px;
    padding: 12px 20px !important;
    font-size: 22px;
    font-weight: 500;
    border: none;
    width: 223px;
}
.secondaryBtnOuter {
    background: #3B56A7;
    color: #Fff;
    border-radius: 8px;
    padding: 10px 20px !important;
    font-size: 14px;
    font-weight: 500;
    border: none;
}
.secondaryBtnSmall {
    background: #3B56A7;
    color: #Fff;
    border-radius: 8px;
    padding: 6px 20px !important;
    font-size: 12px;
    font-weight: 500;
    border: none;
}
.outerBtn {
    display: inline-block;
    background: #444444;
    color: #fff;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin: 0;
}
.linkTxt
{
    font-size: 16px;
    color: #364F9F;
    display: inline-block;
    line-height: normal;
    font-weight: 500;
}
.otherLink
{
    /* font-size: 16px; */
    color: #388E1E;
    display: inline-block;
    line-height: normal;
}
.img-container
{
    position: relative;
}
/* Start Custom Checkbox */
.checkBoxTxt {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #575757;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 1px;
} 
.checkBox {
    appearance: none;
    display: none;
}
.checkBoxTxt span
{
    display: inline-block;
    text-align: start;
}
.checkBoxTxt:before {
    content:'';
    -webkit-appearance: none;
    /* background-color: transparent; */
    border: 2px solid #D3D3D3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 7px;
}
.checkBox:checked + .checkBoxTxt:before
{
    background: #388E1E;
    border: 2px solid #388E1E;
}
.checkBox:checked + .checkBoxTxt:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0%, -60%) rotate(45deg);
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    }
/* End Custom Checkbox */

/* Scrollbar-Css */
.scrollbar
{
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    width: 6px;
    height: 10%;
}
.scrollbar-track
{
    background-color:#D9D9D9
}
.scrollbar-thumb {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    border-radius:16px;
    border:0px solid #D9D9D9;
}
::-webkit-scrollbar
{
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    width: 6px;
    height: 10%;
}
::-webkit-scrollbar-track {
    background-color:#D9D9D9
}
::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    border-radius:16px;
    border: 0px solid #D9D9D9;
}
::-moz-scrollbar
{
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    width: 6px;
    height: 10%;
}
::-moz-scrollbar-track {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
}
::-moz-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border: 0px solid #fff;
}
/* End Scrollbar Css */
button:focus, button:focus-visible, .form-select:focus, .form-select:focus-visible, .form-control:focus, .form-control:focus-visible
{
    box-shadow: none;
    outline: none;
}
button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
}
input:focus-visible {
    outline: 0px;
}
button {
    background: transparent;
    outline: 0;
    border: 0;
}
.customFormControl {
    width: 100%;
    border: 1px solid #c7c7c7!important;
    padding: 12px;
    border-radius: 5px;
    font-size: 15px;
}
.customFormControl::placeholder
{
    color: #5E5F5E;
    font-weight: 400;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*---- End Common Css ----*/


/* Start MainHeader Css */
.nav-item {
    margin-right: 10px;
}
.nav-link {
    letter-spacing: 1px;
    color: #fff !important;
    font-size: 16px;
    font-weight: 300;
    transition: all 0.3s;
}
.nav-link.active {
    font-weight: 500;
}
.navbar-brand img {
    width: 132px;
}
#basic-navbar-nav
{
    position: relative;
    height: 100% !important;
}
.loginDetails
{
    display: flex;
    position: relative;
}
.loginDetails:before {
    position: absolute;
    content: '';
    background: #fff;
    height: 34px;
    width: 1px;
    left: -18px;
    top: 50%;
    transform: translate(-50%, -50%);
}
.mainNavBar
{
    /* background: transparent; */
    position: sticky;
    width: 100%;
    z-index: 999;
    top:0;
}
#basic-navbar-nav .primaryBtn
{
    font-size: 16px;
}
.rightNav_{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
/* End MainHeader Css */
/*============== Start Home Page ==============*/

/* Start Footer Css */
.footer {
    background: #fff;
    padding: 23px 0;
    margin-top: auto;
}
.footerMenus {
    margin-top: 10px;
}
.footerItems {
    margin-bottom: 8px;
}
.footerLinks {
    font-size: 16px;
    color: #262626!important;
    font-weight: 400;
    padding: 0;
}
.socialItems {
    display: inline-block;
    margin-right: 18px;
}
.socialLinks img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}
.footerBottom {
    border-top: 1px solid hsl(0deg 0% 35% / 24%);
    margin-top: 30px;
    padding: 21px 0;
}
/* End Footer Css */
/* Start Sidebar Css */
.sidebarLinks.active span{
    color:#435FB5;
    font-weight: 700;
    font-size: 13px !important;
}
.adminSidebar .headTxtBlueSmall{
    font-weight: bold;
}
.sidebarItems{
    margin-bottom: 20px;
}
.sidebarLinks img {
    width: 21px;
    height: 21px;
    object-fit: none;
    margin-right: 7px;
}
.sidebarLinks span {
    font-size: 14px;
    color:#34363b;
}
.adminSidebar .advData .headingSmall_{
    font-size: 17px;
    font-weight: bold;
    color: #435FB5;
}
/* End Sidebar Css */
/* Start Dashboard Page Css */
.trafficDetails .timeSpot{
    font-size: 12px;
    font-weight: bold;
    color: #6c6c6c;
}
.trafficDetails {
    border: 1px solid #efefef;
    border-radius: 7px;
    padding: 14px 24px 18px;
}
.userTxtBold{
    font-size: 12px;
    color:#01346a;
    font-weight: bold;
}
/* End Dashboard Page Css */
/* Start Support Page */
.manageUser{
    padding:18px 22px;
    background-color: #fff;
    height: 100%;
}
.userAvailable{
    display: flex;
    align-items: center;
    justify-content: end;
    margin:60px 0 55px 0;
}
.manageUserTable{
    width: 100%;
}
.manageUserTable thead{
    border-bottom: 1px solid rgb(199, 199, 199);
}
.manageUser .primaryBtn{
    padding:11px 32px !important;
    font-size: 16px !important;
}
.manageUserTable th{
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 14px;
}
.manageUserTable td{
    font-size: 13px;
    font-weight: 500;
    padding: 14px 0;
}
.pendingTxt{
    color: #c5c5c5;
}
.trashIcon{
    cursor: pointer;
    width: 22px;
    height: 22px;
}
.supportFilter{
    font-size: 13px;
    color: #4C4C4C;
    font-weight: 500;
    background: #f7f7f7;
    padding: 8px;
    border-radius: 5px;
    border: none;
    outline: none;
}
.searchFilter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.customPagination {
    display: flex;
    justify-content: center;
}
.customPagination .pagination {
    /* position: relative!important; */
}
.customPagination .pagination ul {
    display: flex;
    justify-content: right;
    width: 100%;
    align-items: center;
}
.customPagination .previous{
    position: absolute;
    left: 12px;
}
.customPagination .next{
    position: absolute;
    right: 12px;
}
.customPagination .next, .customPagination .previous {
    background: transparent!important;
    border-radius: 4px;
    font-size: 17px;
    color: #767676;
    font-weight: 600;
    margin: 0 4px;
    padding: 3px 11px;
    /* position: absolute;
    left: 0; */
}
.customPagination .next a, .customPagination .previous a {
    align-items: center;
    display: flex;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    padding: 0;
    color: #6a6a6a !important;
    font-weight: 700;
}
.customPagination .next i, .customPagination .previous i {
    color: #959393;
}
.customPagination .selected {
    background: #4d6ac4;
    border-radius: 4px;
    color: #fff!important;
    padding: 3px 11px;
}
.customPagination li {
    /* background: #e2e2e2; */
    border-radius: 4px;
    color: #767676;
    font-size: 17px;
    font-weight: 600;
    margin: 0 4px;
    padding: 3px 11px;
}
.double-arrow{
    font-size: 15px;
    font-weight: 400;
    color: #3e59ad;
}
/* End Support Page */
/* Start Dashboard Layout Css */
.main{
    display: flex;
    width: 100%;
    margin:60px 0;
}
.vertical-tab{
    width: 220px;
    margin-right: 20px;
}
.content_areas{
    width: calc(100% - 240px);
}
/* End Dashboard Page Css */
/* Start Privacy Policy Page Css */
.memberHead_ {
    font-size: 27px;
    color: #3B57A9;
}
.memeberHead_ {
    text-align: center;
    display: inline-block;
}
.privacyHeading {
    font-size: 18px;
    margin: 10px 0;
}
.memberImage {
    width: 100%;
}
/* End Privacy Policy Page Css */
/*============== Start AboutUs Page ==============*/
.homeBanner {
    background: url(../images/bannerBg.png);
    background-size: cover;
    background-position: bottom;
    height: 100%;
    padding-top: 9%;
    padding-bottom: 14rem;
    overflow: hidden;
    width: 100%;
}
.aboutUs .homeBanner
{
    height: 100%;
    padding-top: 0%;
    padding-bottom: 0%;
    display: flex;
    flex-direction: column;
}
.aboutContainer {
    /* height: calc(100vh - 0px - 200px); */
    padding-top: 16rem;
    display: flex;
    align-items: end;
    padding-bottom: 1.5rem;
}
.aboutMenusContainer {
    display: flex;
    align-items: center;
    width: 100%;
    /* overflow: auto; */
}
.aboutMenus {
    padding: 22px 0;
    background: #fff;
}
.aboutItem {
    margin-right: 50px;
    white-space: pre;
}
.aboutLinks {
    font-size: 18px;
    color: #343434;
    font-weight: bold;
    text-transform: uppercase;
}
/* Start AboutFounder Css */
.aboutFounder {
    background-image: linear-gradient(to right, #4D4D4D ,#343434);
    padding: 7em 0;
}
.aboutList{
    font-size: 24px;
    padding-left: 23px
}
.aboutList li{
    list-style: disc !important;
}
/* End AboutFounder Css */
/* Start Mission Css */
.mission {
    background: #fff;
    padding: 5rem 0;
}
.missionBoard {
    background: #e8e8e8;
    padding: 5rem 0;
}
/* End Mission Css */
/* Start LeaderShip Css */
.leadership {
    padding: 60px 0;
}
.leaderShipArea {
    display: flex;
    background: #fff;
    padding: 28px;
    box-shadow: 0px 20px 30px #00000005;
    border-radius: 15px;
    margin-bottom: 20px;
}
.leaderShipArea img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}
/* End LeaderShip Css */
/* Start NewsPress Css */
.newsPress
{
    background-image: linear-gradient(to right, #4D4D4D ,#343434);
    padding: 80px 0;
}
.newsCard {
    background: #fff;
    border-radius: 15px;
    padding: 26px;
    height: 100%;
    position: relative;
}
.newsCard h5.innerSubtext
{
    line-height: 1.5;
}
.readMoreData
{
    margin-top: 63px;
}
.newsCard .innerSubtext {
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
}
.newsCard .innerSubtextbold {
    font-size: 22px;
}
.readMore {
    font-size: 18px;
    color: #1C3276;
    font-weight: 600;
    position: absolute;
    bottom: 20px;
    text-decoration: underline;
    text-underline-position: from-font;
}
.missionBoard .innerSubtext {
    text-align: justify;
}
.aboutContainer .bannerHead {
    font-size: 58px;
}
.bannerHead {
    font-size: 42px;
    color: #fff;
    font-weight: 400;
}
.aboutContainer .bannerTxt {
    font-size: 26px;
}
.bannerTxt {
    color: #fff;
    font-size: 23px;
    /* margin: 0; */
}
.aboutFounder .bannerHead {
    font-size: 42px;
}
.aboutUs .otherLink {
    font-size: 17px;
    color: #5cc13e;
}
.aboutFounder .bannerTxt {
    font-size: 17px;
    text-align: justify;
}
.aboutLady {
    width: 100%;
}
.containerBoard {
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    align-items: start;
}
.boardImg {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    border-radius: 5px;
}
/* End NewsPress Css */
.newsCard {
    background: #fff;
    border-radius: 15px;
    padding: 26px;
    height: 100%;
    position: relative;
}
.newsCard .innerSubtextbold {
    font-size: 22px;
}
.newsCard .innerSubtextBlue {
    color: #2f4ca6;
    font-size: 17px;
}
.newsCard .innerSubtext {
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
}
.readMoreData {
    margin-top: 63px;
}
.newsCard .readMore {
    font-size: 16px;
    color: #2f4ca6;
    font-weight: 400;
}
/*============== End AboutUs Page ==============*/
/* Start Contact Us Page Css */
.contactLeft {
    display: inline-block;
    width: 100%;
    position: relative;
    height: 100%;
}
.discription_ {
    color: #343434;
    font-size: 16px;
}
.contactHead_ {
    color: #3B57A9;
    font-size: 50px;
}
.paragraph_ {
    color: #343434;
    font-size: 15px;
    line-height: 26px;
}
.contactInfo_ {
    position: absolute;
    bottom: 0;
}
.form-control {
    border: 1px solid #6E6E6E;
    border-radius: 5px;
    color: #5E5F5E;
    font-size: 15px;
    padding: 17px 17px;
}
.contactRight .primaryBtn {
    width: 100%;
}
.contectUs_ .memberHomeImage_ {
    padding: 70px;
}
/* End Contact Us Page */
/* Start Faq Page Css */
.containerBox {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 24px;
    position: relative;
    height: 100%;
}
.faqcontentBox_ {
    padding: 15px 0;
}
.faq-row-wrapper {
    padding: 10px 30px;
}
.faqcontentBox_ .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    padding: 10px 0 14px !important;
}
.styles_faq-row-wrapper__3vA1D h2 {
    margin: 0;
    color: var(--title-text-color, black);
    font-size: var(--title-text-size, 30px);
}
.faqcontentBox_ .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    padding: 10px 0 14px !important;
}
.faqcontentBox_ .row-title-text {
    font-weight: 600;
}
.faqcontentBox_ .styles_icon-wrapper__2cftw {
    width: 22px;
    height: 22px;
    background: #3B57A9;
    text-align: center;
    border-radius: 50px;
    color: #fff !important;
    font-weight: 600;
    line-height: 19px;
    font-size: 15px;
}
.faqcontentBox_ .styles_closed__39w54 .styles_icon-wrapper__2cftw {
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
}
.faqcontentBox_ .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    padding: 10px 0 14px !important;
}
/* Start Help Desk Css */
.postBtn{
    background-color: #4764ba;
    color: #fff !important;
    font-size: 17px;
    padding:6px 22px;
    border-radius: 5px;
}
.searchByKeywordOuter {
    position: relative;
    display: inline-block;
}
.searchByKeywordOuter input{
    padding-right: 35px;
}
.searchByKeywordOuter .inputClose{
    position: absolute;
    right: 5px;
    top: 5px;
}
.swal-modal .swal-footer{
    text-align: center;
}
.swal-modal .swal-button.swal-button--confirm{
    background-color: #4764ba !important;
}
.ck-content{
    min-height: 120px !important;
}
/* End Help Desk Css */
/* Start Post Artical Css */
.mendatoryFields{
    color: #8bc349;
}
.customLabel{
    font-size: 17px;
    color: #000000;
    font-weight: 500;
}
.customSelect{
    border: 1px solid #c7c7c7;
    background: transparent;
    border-radius: 5px;
    padding: 13px 20px;
    outline: none;
    display: block;
    appearance: none;
    background-image: url(../images/downBlackArrow.svg)!important;
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 29px 8px;
}
/* End Post Artical Css */


/*---Eye Icon*---*/
.show-icon {
    background-image: url('/public/img/Show.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 15px;
    width: 15px;
    position: absolute;
    bottom: 17px;
    right: 23px;
    cursor: pointer;
}

.hide-icon.hide-icon {
    background-image: url('/public/img/Hide.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 15px;
    width: 15px;
    position: absolute;
    bottom: 17px;
    right: 23px;
    cursor: pointer;
}


.uploadImgebox {
    display: inline-block;
    width: 100%;
    background: #F6F8FF 0% 0% no-repeat padding-box;
    border: 2px dashed #435FB5;
    border-radius: 8px;
    text-align: center;
    padding: 50px;
    margin-bottom: 20px;
}

.uploadbtnn {
    background: #2F4894 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    padding: 9px 40px;
    font-weight: 500;
    display: inline-block;
    margin: 0;
    cursor: pointer;
}

.uploadText {
    color: #364F9F;
    font-size: 12px;
    margin: 0;
}

.cateSelectbox {
    background: #FFFFFF;
    border: 1px solid  #d8d8d8;
    border-radius: 4px;
    display: inline-block;
    width: 100%;
    padding: 14px 25px 14px 10px;
    color: #3B3B3B;
    font-size: 15px;
    appearance: none;
    background-image: url(../images/downArrow.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 29px 8px;
    outline: none !important;
}
.headingSmall_ {
    font-size: 14px;
}
.searchWrapper {
    border: 1px solid #c7c7c7!important;
    padding: 11px!important;
}
.highlightOption {
    background: transparent!important;
    color:#000 !important;
}
.multiSelectContainer li:hover {
    background: #2d509a!important;
    color: #fff;
}
.chip {
    background: #2d509a!important;
    border-radius: 0!important;
    white-space: pre-wrap!important;
    border-radius: 10px!important;
    padding: 8px 10px!important;
}
.uploadProfile_ {
    align-items: center;
    background: #f2f2f2 0 0 no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    margin: 20px 0;
    padding: 8px 17px;
}
.w3-round {
    background: #cfcfcf;
    border-radius: 4px;
    height: 41px;
    margin-right: 14px;
    padding: 3px;
    width: 41px;
}
.profileboldHead {
    color: #343434;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
}
.profileboldgraph {
    color: #5e5f5e;
    font-size: 12px;
    margin: 0;
}
.uploadProfile_ i {
    align-items: center;
    background: #d3d2d2;
    border-radius: 50px;
    color: #222;
    display: flex;
    font-size: 12px;
    height: 30px;
    justify-content: center;
    margin-left: auto;
    text-align: center;
    width: 30px;
}
.customLabel{
    font-size: 16px;
    color: #343434;
    margin-bottom: 14px;
    font-weight: 600;
}
.fieldMandatory {
    color: #5cc13e;
}